@import '~react-awesome-slider/src/core/styles';

.awssld {
  --loader-bar-color: #00A651;
  --content-background-color: #001C54;
}

.awssld__content {
  & > div {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }
  & > img {
    opacity: 0.6;
    object-fit: cover;
  }
}